$(function () {
    $('.page-navigation .next a').html('»');
    $('.page-navigation .previous a').html('«');


    $(window).on('resize', function (e) {
        $('.article-preview-wrapper').each(function () {
            var overlay = $(this).find('div.hover-overlay');

            if ($(window).width() < 768 || $('body').hasClass('neos-backend')) {
                $(this).off();
                overlay.css('bottom', '');
            } else {
                var height = $(this).find('div.intro').outerHeight();
                var newHei = '-' + height + 'px';

                overlay.css('bottom', newHei);

                $(this).off().hover(function (event) {
                    overlay.css('bottom', '');
                }, function (event) {
                    overlay.css('bottom', newHei);
                });
            }
        });
    }).trigger('resize');

});
