jQuery.migrateMute = true;
jQuery.migrateTrace = true;

// Resize work boxes
var lastWorkBoxHeight = 0;
function resizeWorkBoxes() {
  var workBoxHeight = $("#page .front img").height();
  if (lastWorkBoxHeight != workBoxHeight) {
    $("#page .back").css({
      height: workBoxHeight
    });

    $("#page .work-item").css({
      height: workBoxHeight
    });
  }

  setTimeout(resizeWorkBoxes, 250);
}

// Resize buttons function
var lastButtonWidth = 0;
function resizeButtons() {
  var hw = $('#start-button').width();

  if (lastButtonWidth != hw) {
    lastButtonWidth = hw;

    $('.hoehenziel.weitenziel').animate({
      height: hw,
      width: hw,
      'line-height': hw
    }, 750, function () {
      $('.hoehenziel.weitenziel.ignore-width-of-block').css('width', 'auto');
      $('.hoehenziel.weitenziel.ignore-width-of-block').css('padding', '0 5px');

      setTimeout(resizeButtons, 250);
    });
  } else {
    setTimeout(resizeButtons, 250);
  }
}

// Resize team image items
var lastTeamImageItemHeight = 0;
function resizeTeamImageItems() {

  var teamImageItemHeight = $("#page div[id*='-team-image'] div[id*='-team-image-item']").first().width() + 10;
  if (lastTeamImageItemHeight != teamImageItemHeight) {

    $("#page div[id*='-team-image'] div[id*='-team-image-item']").css({
      height: teamImageItemHeight
    });
  }

  setTimeout(resizeTeamImageItems, 250);
}

function initGallery(callback) {

  $('.gallery').each(function () {
    var gallery = $(this);
    var galleryNavItems = gallery.find('.gallery-nav .gallery-nav-item');

    var i = 0;
    galleryNavItems.each(function () {
      if (i == 0) {
        $(this).addClass('active');
      }

      i += 1;

      if (galleryNavItems.length == i) {
        gallery.find('.gallery-slider ul li img').on('load', function () {
          var loadGallery = function () {
            gallery.find('.gallery-slider .gallery-loading').hide();
            gallery.find('.gallery-slider ul li').show();

            var unslider = gallery.find('.gallery-slider').unslider({
              delay: false,
              fluid: true,
              complete: function (el) {
                $(el).parent().find('.gallery-nav .gallery-nav-item').removeClass('active');
                var item = $(el).parent().find('.gallery-nav .gallery-nav-item').get($(el).data('unslider').current);
                $(item).addClass('active');
              }
            });

            galleryNavItems.on('click', function (event) {
              event.preventDefault();

              var clickedItemIndex = $(this).index();
              unslider.data('unslider').move(clickedItemIndex);
            });

            gallery.find('.gallery-slider .arrow').on('click', function (event) {
              event.preventDefault();

              var fn = this.className.split(' ')[1];
              unslider.data('unslider')[fn]();
            });
          };

          setTimeout(loadGallery, 3000);
        });
      }
    });
  });

  if (callback != undefined && typeof callback === 'function') callback();
}

function initToolTips() {
  if (!('ontouchstart' in window)) { // No tooltips on touch devices
    $('body').tooltip({
      selector: '*[data-toggle="tooltip"]'
    });
  }
}

function initTeamToolTip(toolTipElement) {
  var toolTipTitle = $(toolTipElement).html();

  $(toolTipElement).html('');

  $('body').tooltip({
    selector: toolTipElement,
    container: toolTipElement,
    html: true,
    title: toolTipTitle,
    trigger: 'click'
  });
}

function registerTeamToolTipEvents() {
  // On show team tool tip
  $("#page div[id*='-team-image'] div[id*='-team-image-item']").on('show.bs.tooltip', function () {
    // Hide all the other team tool tips
    $("#page .team-image-inner .tooltip").each(function () {
      $(this).tooltip('hide');
    });

    // Hide team tool tips on clicking in different area
    var container = $(this);
    $(document).mouseup(function (e) {
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        $("#page .team-image-inner .tooltip").each(function () {
          $(this).tooltip('hide');
        });
      }
    });

    $('body').bind("touchstart", function (e) { // Same for touch devices
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        $("#page .team-image-inner .tooltip").each(function () {
          $(this).tooltip('hide');
        });
      }
    });
  });

  // Hide team tool tips on scroll
  $(window).scroll(function () {
    $("#page .team-image-inner .tooltip").each(function () {
      $(this).tooltip('hide');
    });
  });
}

function registerSocialMediaButtons() {
  return false; // see gdpr.js
}

function initFlipCards() {
  $("#page .karte").on('mouseenter', function () {
    if ($(this).children("div").hasClass('flipped')) {
      $(this).children("div").removeClass("flipped");
    } else {
      $("#page .karte").children("div").removeClass("flipped");
      $(this).children("div").addClass("flipped");
    }
  });
}

function animationHover(flaeche, element, animationIn, animationOut) {
  flaeche = $(flaeche);
  flaeche.hover(
    function () {
      element.addClass('visible animated ' + animationIn);
    },
    function () {
      //wait for animation to finish before removing classes
      window.setTimeout(function () {
        element.removeClass(animationIn);
        element.addClass(animationOut);
        window.setTimeout(function () {
          element.removeClass('visible animated ' + animationOut);
        }, 250);
      }, 5);
    });
}

$(document).ready(function () {

  // Scroll to contact form
  if ($('.help-inline').length > 0 || $('#contact-form-confirm').length > 0) {
    $('html, body').animate({
      scrollTop: $('.typo3-neos-nodetypes-form').offset().top
    }, 2000);
  }

  // Register social media buttons
  registerSocialMediaButtons();

  // Initialize gallery
  initGallery(resizeButtons);

  // Initialize general tool tips
  initToolTips();

  // Register team tool tip events
  registerTeamToolTipEvents();

  // Resize buttons
  resizeButtons();
  $(window).resize(resizeButtons);

  // Resizing team images
  resizeTeamImageItems();

  // Resizing work boxes
  resizeWorkBoxes();

  // Initialize flip cards
  initFlipCards();

  animationHover($("#page #start-page .kontakt"), $("#page #start-page .bolle"), "bounce");
  animationHover($("#page #start-page .jobs"), $("#page #start-page .jobs"), "rubberBand");
  animationHover($("#page #start-page .service"), $("#page #start-page .service"), "tada");
  animationHover($("#page #start-page .work"), $("#page #start-page .supertyp"), "bounceOutRight");
  animationHover($("#page #start-page .team"), $("#page #start-page .woot"), "zoomInLeft", "zoomOutRight");
  animationHover($("#page #start-page .logo"), $("#page #start-page .help"), "rotateInUpRight", "rotateOutDownRight");

});
